:root {
    --grid-gap: 5px;
    --board-height: min(400px, 40vh);
    --board-width: calc(var(--board-height) * 0.83333);
}


#game-board {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-row-gap: var(--grid-gap);
    width: var(--board-width);
    height: var(--board-height);
    margin: 8px auto;
}

.word-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: var(--grid-gap);
    width: 100%;
}

.letter {
    border: 2px solid var(--border-color-no-guess);
    user-select: none;

    /* center text */
    display: flex;
    justify-content: center;
    align-items: center;

    /* font style */
    font-size: calc(var(--board-height) * 0.08);
    font-weight: bold;
}

.letter[data-animation='pop'] {
    animation-name: PopIn;
    animation-duration: 100ms;
}

.letter[data-animation='shake'] {
    animation-name: Shake;
    animation-duration: 600ms;
}

.letter[data-animation='flip'] {
    animation-name: Flip;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
}

.letter[data-animation='bounce'] {
    animation-name: Bounce;
    animation-duration: 1000ms;
    animation-timing-function: ease-in-out;
}

@keyframes PopIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    40% {
        transform: scale(1.1);
        opacity: 1;
    }
}

@keyframes Shake {
    10%, 90% {
        transform: translateX(-1px);
    }

    20%, 80% {
        transform: translateX(2px);
    }

    30%, 50%, 70% {
        transform: translateX(-4px);
    }

    40%, 60% {
        transform: translateX(4px);
    }
}

@keyframes Flip {
    from {
        transform: rotateX(0);
    }

    50% {
        transform: rotateX(-90deg);
    }

    to {
        transform: rotateX(0);
    }
}

@keyframes Bounce {
    0%, 20% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    50% {
        transform: translateY(5px);
    }
    60% {
        transform: translateY(-15px);
    }
    80% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0);
    }
}

.guessing {
    border: 2px solid var(--border-color-guessing);
    color: var(--font-color-guessing);
    background: var(--background-guessing);
}

.absent {
    border: none;
    color: var(--font-color-guessed);
    background: var(--background-absent);
}

.present {
    border: none;
    color: var(--font-color-guessed);
    background: var(--background-present);
}

.correct {
    border: none;
    color: var(--font-color-guessed);
    background: var(--background-correct);
}