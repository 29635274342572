.toast-bag {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translate(-50%, 0);
}

.toast {
    color: white;
    background: black;
    border-radius: 4px;
    /*width: fit-content;*/
    padding: 1rem;
    margin: 1rem 0;
    font-weight: bold;

    /*pointer-events: none;*/
}

.toast a {
    color: white;
}

#modal-toast-bag {
    z-index: 5;
}

.toast-fade {
    animation: toast-fade-out 4s;
}

@keyframes toast-fade-out {
    from {opacity: 1}
    33% {opacity: 1}
    50% {opacity: 0}
}
