header {
    border-bottom: 1px solid #d3d6da;
    margin: 8px 0;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
}

#header-title {
    font-weight: bold;
    font-size: 36px;
    letter-spacing: 0.2rem;
}

#header-left {
    position: absolute;
    left: 0.5rem;

    display: flex;
}

.lang-switch {
    font-weight: bold;
    color: #878a8c;

    border: 2px solid #878a8c;
    padding: 0.08rem 0.4rem;
    border-radius: 20px;

    cursor: pointer;
    user-select: none;
}

#header-help {
    cursor: pointer;
    margin-right: 0.5rem;
}

#header-stats {
    cursor: pointer;
}

#header-right {
    position: absolute;
    right: 0.5rem;

    display: flex;
}
