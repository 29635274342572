#modal-bag {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.5);
}

#modal-bag.shown {
    display: flex;
    animation-name: SlideIn;
    animation-duration: 200ms;
}

#modal-bag.hidden {
    animation-name: SlideOut;
    animation-duration: 400ms;
}

.modal {
    display: none;

    position: relative;
    width: 90%;
    max-width: var(--max-game-width);

    background: white;
    border-radius: 8px;

    border: 1px solid #f6f7f8;
    box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
}

.modal.shown {
    display: block;
}

.modal-content {
    margin: 2rem;
    font-size: 14px;
}

.modal-title {
    font-weight: bold;
    font-size: 1rem;
    display: inline;

    width: 100%;
    text-align: center;
}

.modal-close-button {
    float: right;
    cursor: pointer;
}

.modal-content > h2 {
    font-size: 1rem;
}

.modal-content > .word-row {
    max-width: var(--board-width);
    height: 62px;
}

#thanks-link {
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 450px) {
    .modal-content > .word-row {
        max-width: 270px;
        height: 50px;
    }
}

@keyframes SlideIn {
    0% {
        transform: translateY(30px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes SlideOut {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    45% {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translateY(60px);
    }
}

.stats-container {
    display: flex;
    width: 100%;
    justify-content: center;

    margin: 1rem 0;
}

.stat {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 50px;

    margin: 0 1rem;

    text-align: center;
}

.stat-value {
    font-size: 2.25rem;
}

.stat-name {
    font-size: 12px;
}

.dist-container {
    margin: 1rem 0;
}

.graph-container {
    display: flex;
    margin: 0.3rem;
}

.graph-id {
    width: 16px;
    margin: 0 4px;
    line-height: 22px;

    font-weight: bold;
}

.graph {
    background: #787c7e;
    min-width: 1.3rem;
}

.graph.most-recent {
    background: var(--background-correct);
}

.graph-percent {
    color: white;
    font-weight: bold;
    padding: 0.2rem 0.4rem;
}

#countdown-share {
    display: grid;
    grid-template-columns: 2fr 21px 1fr;
}

#timer-bag {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: auto;
}

#countdown-timer {
    font-size: 2rem;
    margin: 1rem 0;

    width: 100%;
    text-align: center;
}

.vr {
    width: 1px;
    margin: 0 10px;
    background: black;
}

#share-button {
    background: var(--background-correct);

    color: white;
    font-size: 20px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;

    cursor: pointer;

    height: 52px;
    padding: 0 1rem;

    border: none;
    border-radius: 5px;
}