.keyboard {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 8px;
    margin: 10px 8px;

    user-select: none;
}

.keyboard-row1 {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
}

.keyboard-row2 {
    display: grid;
    grid-template-columns: 1fr repeat(9, 2fr) 1fr;
}

.keyboard-row3 {
    display: grid;
    grid-template-columns: 3fr repeat(7, 2fr) 3fr;
}

.keyboard-key {
    background: #d3d6da;
    color: black;
    border-radius: 4px;
    height: 58px;
    margin: 0 3px;

    /* center text */
    display: flex;
    align-items: center;
    justify-content: center;

    /* font */
    font-weight: bold;
    font-size: 13px;

    cursor: pointer;
}

.keyboard-key.absent {
    color: var(--font-color-guessed);
    background: var(--background-absent);
}

.keyboard-key.present {
    color: var(--font-color-guessed);
    background: var(--background-present);
}

.keyboard-key.correct {
    color: var(--font-color-guessed);
    background: var(--background-correct);
}

.enter-key {
    font-size: 12px;
}
