:root {
    --max-game-width: 500px;

    /* guess letter colors */
    --border-color-guessing: #878a8c;
    --border-color-no-guess: #d3d6da;

    --font-color-guessing: black;
    --font-color-guessed: white;

    --background-guessing: white;
    --background-absent: #787c7e;
    --background-correct: #6aaa64;
    --background-present: #c9b458;

    font-family: Arial, sans-serif;
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;

    /*overflow: hidden;*/
    /*background: cadetblue;*/
}

#game {
    max-width: var(--max-game-width);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}